import React, { Component } from "react";
import Button from "components/CustomButtons/Button.jsx";
import { tl } from "framework/utils/Translator";
import { Menu, MenuItem, withStyles, withWidth } from "@material-ui/core";
import API from "framework/utils/API";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import ExcelIcon from "assets/img/icons/material/excel.png";
import PDFIcon from "@material-ui/icons/DescriptionOutlined";
import CaretIcon from "@material-ui/icons/KeyboardArrowDown";

import FileDownload from "js-file-download";

class ReportDownloadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      anchorEl: null,
    };
    this.FileDownload = FileDownload;
    this.fileFormats = {
      excel: ".xlsx",
      pdf: ".pdf",
    };
  }

  handleDownload = (reportType) => {
    let record = this.props.only?{}:this.props.record;
    record.reportType = reportType;
    let format = this.fileFormats[reportType];
    this.setState({ isLoading: true, anchorEl: null });
    API("download", this.props.model, record)
      .then((response) => {
        this.FileDownload(response.data, "report" + format);
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1000);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response.status === 401 && this.props.error) {
          this.props.error(error.response.data.fields);
        }
      });
  };

  handleOpen = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, width, only } = this.props;
    return (
      <>
        <Button
          color="success"
          key="download-pdf"
          size="sm"
          className="rounded-button"
          disabled={this.state.isLoading}
          onClick={only?()=>{this.handleDownload(this.props.only)}:this.handleOpen}
          {...((width !== "xs" && !this.props.only) ? { style: { paddingRight: "5px" } } : {})}
        >
          <DownloadIcon />
          {width !== "xs" && (
            <>
              {tl("Download")}
              {!this.props.only && <CaretIcon style={{ marginLeft: "15px" }} />}
            </>
          )}
        </Button>
        <Menu
          id="downloadMenu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={() => this.handleDownload("excel")}>
            <div className={classes.list}>
              <img src={ExcelIcon} className="imgIcon" />
              <div className="label">Excel</div>
            </div>
          </MenuItem>
          <MenuItem onClick={() => this.handleDownload("pdf")}>
            <div className={classes.list}>
              <PDFIcon className="icon" />
              <div className="label">PDF</div>
            </div>
          </MenuItem>
        </Menu>
      </>
    );
  }
}

const styles = {
  list: {
    display: "flex",
    alignItems: "center",
    width: "200px",
    "&>.icon": {
      marginRight: "10px",
    },
    "&>.imgIcon": {
      marginRight: "10px",
      width:"24px"
    },
    "&>.label": {
      fontFamily: "Quicksand",
      flexGrow: "1",
    },
  },
};

export default withWidth()(withStyles(styles)(ReportDownloadButton));
