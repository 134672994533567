export function push(currentState, payload) {
  return {
    type: "PUSH",
    currentState: currentState,
    payload: payload,
  };
}

export function back(payload) {
  return {
    type: "BACK",
    payload: payload,
  };
}

export function backFrom(payload) {
  return {
    type: "BACK_FROM",
    payload: payload,
  };
}

export function pop(payload) {
  return {
    type: "POP",
    payload: payload,
  };
}

export function update(payload) {
  return {
    type: "UPDATE",
    payload: payload,
  };
}

export function empty(payload) {
  return {
    type: "EMPTY",
    payload: payload,
  };
}

export function setNotification(isOpen, message, color) {
  return {
    type: "SET_NOTIFICATION",
    payload: {
      isOpen: isOpen,
      message: message,
      color: color,
    },
  };
}

export function setFormEdited(payload) {
  return {
    type: "SET_FORM_EDITED",
    payload: payload,
  };
}

export function setFormUnedited(payload) {
  return {
    type: "SET_FORM_UNEDITED",
    payload: payload,
  };
}


export function setUnsavedConfirm(payload){
  return {
    type:"SET_UNSAVED_CONFIRM",
    payload
  }
}

export function setUnsavedConfirmClose(){
  return {
    type:"SET_UNSAVED_CONFIRM_CLOSE"
  }
}