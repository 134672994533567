import AccountingTranslation from "app/modules/Accounting/Translation";
import PropertyTranslation from "app/modules/Property/Translation";
import HrTranslation from "app/modules/Hr/Translation";
import ShopTranslation from "app/modules/Shop/Translation";
import BaseTranslation from "app/modules/Base/Translation";
import WebTranslation from "app/modules/Web/Translation";
import FreightTranslation from "app/modules/Freight/Translation";
import SaasTranslation from "app/modules/Saas/Translation";
import realEstateTranslation from "app/modules/RealEstate/Translation";
import themeParkTranslation from "app/modules/ThemePark/Translation";
import BakeryTranslation from "app/modules/Bakery/Translation";
import RestaurantTranslation from "app/modules/Restaurant/Translation";
import Config from "company/Config";
import Cookies from "js-cookie";

let renderedConfig = null;
if (Config.saasConfig && Cookies.get("remoteConfig")) {
  let remoteConfig = JSON.parse(Cookies.get("remoteConfig"));
  renderedConfig = remoteConfig;
} else {
  renderedConfig = Config;
}

const Translations = {
  id: {
    //moment.js
    "01": "Januari",
    "02": "Februari",
    "03": "Maret",
    "04": "April",
    "05": "Mei",
    "06": "Juni",
    "07": "Juli",
    "08": "Agustus",
    "09": "September",
    10: "Oktober",
    11: "November",
    12: "Desember",

    //user menu
    changePassword: "Ganti Kata Sandi",
    logout: "Keluar",
    "logout.confirmationText": "Apa Anda yakin akan keluar?",
    unsavedChanges:"Abaikan Perubahan",
    "unsavedChanges.confirmationText":"Masih terdapat perubahan yang belum disimpan di form. Apa Anda ingin lanjut dan mengabaikan perubahan?",

    //languages
    languages: "Bahasa",
    English: "Bahasa Inggris",
    Indonesian: "Bahasa Indonesia",

    //login
    email: "Email",
    login: "Masuk",
    LoginWithFacebook: "Login dengan Facebook",
    LoginWithGoogle: "Login dengan Google",
    password: "Kata Sandi",
    forgotPassword: "Lupa Kata Sandi",
    hidePassword: "Sembunyikan Kata Sandi",
    peekPassword: "Lihat Kata Sandi",

    //password recovery
    passwordRecovery: "Pemulihan Kata Sandi",
    helperTextPasswordRecovery:
      "Harap masukkan Email anda, kami akan kirimkan link untuk memulihkan kata sandi anda",
    helperTextEmailSent:
      "Kami telah mengirimkan email berisi link, harap periksa email anda",
    send: "Kirim",
    sendPreviousMessage:"Kirim Pesan Sebelumnya",

    //passwordReset
    resetYourPassword: "Atur Ulang Kata Sandi",
    newPassword: "Kata Sandi Baru",
    oldPassword: "Kata Sandi Lama",
    confirmPassword: "Ketik ulang Kata Sandi",
    reset: "Simpan Kata Sandi",
    oldPasswordIsWrong: "Kata sandi lama salah",
    passwordsAreDifferent: "Password tidak sama",
    updatePassword: "Perbarui Kata Sandi",
    "passwordUpdate.success": "Kata sandi berhasil diperbarui",

    //user page
    profile: "Profil",
    viewProfile: "Lihat Profil",
    subsription: "Informasi Langganan",
    cancelSubscription: "Batalkan Langganan",
    editProfile: "Ubah Profil",
    ignoreChanges: "Batalkan perubahan",
    ignoreChangesText: "Apakah Anda yakin akan membatalkan perubahan profil",
    "profileUpdate.success": "Profil berhasil diperbarui",

    //notifications
    notifications: "Notifikasi",
    seeAllNotifications: "Lihat Semua Notifikasi",
    allNotifications: "Semua Notifikasi",
    waitASec: "Tunggu Sebentar",
    noNotification: "Tidak Ada Notifikasi",

    //browseTable & form components
    create: "Buat",
    search: "Cari",
    searchPlace: "Cari Tempat",
    Duplicate: "Duplikat",
    select: "Pilih",
    clear: "Kosongkan",
    refreshData: "Muat ulang data",
    filterData: "Saring Data",
    applyFilter: "Terapkan Filter",
    enableFiltering: "Aktifkan pemfilteran",
    disableFiltering: "Nonktifkan pemfilteran",
    deleteData: "Hapus Data",
    delete: "Hapus",
    printData: "Cetak Data",
    actions: "Aksi",
    cancel: "Batal",
    close: "Tutup",
    save: "Simpan",
    update: "Perbarui",
    allData: "Semua Data",
    viewData: "Lihat",
    editData: "Ubah",
    createData: "Baru",
    enableEditing: "Ubah Data",
    createNew:"Buat Baru",
    addNew: "Tambah Baru",
    addMediaUrl: "Tambah URL Video",
    mediaUrl: "URL Video",
    image: "Gambar",
    empty: "Kosong",
    done: "Selesai",
    noRowsFound: "Belum ada data",
    next: "Selanjutnya",
    prev: "Sebelumnya",
    back: "Kembali",
    browsePage: "Laman",
    browseOf: "dari",
    browseRows: "baris",
    clearImageNotice: "Apa Anda yakin akan menghapus semua gambar?",
    "are you sure": "Apa Anda yakin ?",
    "are you sure want to perform":
      "Apa Anda yakin akan melakukan aksi ini terhadap data yang dipilih?",
    "are you sure want to delete": "Apa Anda yakin ingin menghapus",
    "are you sure want to sync": "Apa Anda yakin ingin mensingkronkan data?",
    thisItem: "data ini",
    theseItems: "data-data berikut",
    checkAll: "Centang Semua",
    uncheckAll: "Kosongkan Semua Centang",
    selected: "Dipilih",
    pleaseFillIn: "Harap diisi",
    pleaseRecheckDate: "Silakan cek kembali tanggal",
    pleaseSelect: "Pilih",
    pick: "Pilih",
    fullScreen: "Layar penuh",
    pickFrom: "Pilih Dari",
    pickFile: "Pilih Berkas",
    pickColor: "Pilih Warna",
    changeFile: "Ganti Berkas",
    removeFile: "Hapus Berkas",
    noFile: "Tidak ada Berkas",
    fileIsTooBig: "Ukuran file maksmimum 2MB",
    clickToChange: "Klik untuk ganti...",
    add: "Tambah",
    confirmation: "Konfirmasi",
    confirm: "Konfirmasi",
    yes: "Ya",
    and: "dan",
    no: "Tidak",
    all: "Semua",
    mustBeFilled: "Harus Diisi",
    notANumber: "Isi dengan angka",
    "Data Created": "Data telah ditambahkan",
    "Data Updated": "Data telah diubah",
    "Data Deleted": "Data telah dihapus",
    Download: "Unduh",
    export: "Ekspor",
    sync: "Singkronkan",
    share: "Bagikan",
    copyLink: "Salin Link",
    copyToClipboard:"Salin ke clipboard",
    "linkCopy.success": "Link telah disalin ke clipboard",
    "copy.success": "Teks telah disalin ke clipboard",
    reportType: "Jenis Berkas",
    excel: "Excel",
    pdf: "PDF",
    loading: "Memuat",
    saving: "Menyimpan",
    uploading: "Mengunggah ...",
    uploadError: "Terjadi kesalahan saat mengunggah file, silakan coba lagi",
    errorMaxFileSize: "Ukuran berkas maksimal adalah %s",
    saved: "Disimpan",
    page: "Halaman",
    pleaseWait: "Tunggu Sebentar",
    change: "Ganti",
    pleaseCheckFirst:
      "Silahkan centang terlebih dahulu data sebelum melakukan aksi tersebut",
    serverMaintenance:
      "Maaf, Server sedang dalam pemeliharaan, silakan coba lagi nanti",
    domainNotFound:
      "Maaf, kami tidak dapat menemukan halaman yang anda ingin kunjungi",
    invalidHex: "Kode Warna Hex Tidak Valid",
    bulkActionSuccess: "Aksi Selesai :",
    actionSuccess: "Sukses",
    actionFailed: "Gagal",
    rebuildData: "Buat ulang data",
    rebuildWarning:
      "Perhatian : Jika Anda memilih untuk membuat ulang data, semua data yang memiliki relasi ke blog, kategori, barang, dll, akan terhapus dan dibuat ulang menurut konfigurasi saat ini.",
    syncCompleted: "Sinkronisasi Selesai",
    syncFailed: "Sinkronisasi Gagal, Silakan hubungi administrator Anda",
    addedToList: "Ditambahkan ke daftar barang",
    print: "Cetak",
    startDate: "Tanggal Awal",
    endDate: "Tanggal Akhir",
    startTime: "Waktu Mulai",
    endTime: "Waktu Berakhir",
    applySettings: "Terapkan Pengaturan",

    //attachment
    cropImage: "Crop Image",
    preview: "Pratinjau",
    undoCrop: "Batalkan Pemotongan",
    doneCrop: "Selesai Memotong",

    //--many2one
    typeToSearch: "Cari...",
    noOptionSelected: "Pilih terlebih dahulu",
    browse: "Buka Tabel",
    //--one2many
    deleteRow: "Hapus Baris",
    //status
    currentStatus: "Status saat ini",
    none: "Kosong",
    undefined: "Belum Ditentukan",
    notSet: "Belum Ditentukan",
    unknown: "Tidak Diketahui",
    time: "Jam",
    lockData: "Kunci Data",
    unlockData: "Buka Kunci",
    configuration: "Konfigurasi",
    viewAll: "Lihat Semua",
    noTitle: "Tidak ada judul",
    noDescription: "Tidak ada deskripsi",
    noDataFound: "Data tidak ditemukan",
    noData:"Tidak ada data",
    noDataFoundText:
      "Anda mungkin mengklik link yang salah atau kadaluarsa, hubungi administrator untuk info lebih lanjut",
    minChar: "Minimal %s karakter",
    charsLeft: "%s Karakter lagi ",
    charsComplete: "Jumlah karakter terpenuhi",

    //errors Description
    pleaseTryAgain: "Silahkan Coba lagi",
    inputError:
      "Terjadi kesalahan pada data yang Anda masukkan mohon periksa kembali",
    serverNotFound: "Server tidak ditemukan, silahkan hubungi Admin Sistem.",
    serverError:
      "Server sedang mengalami gangguan, silahkan hubungi Admin Sistem.",
    serverUnavailable:
      "Mohon maaf, server saat ini tidak tersedia, silahkan coba lagi nanti.",
    configNotSavedServerError:
      "Konfigurasi gagal tersimpan, tidak terhubung dengan server",
    configNotLoadedServerError:
      "Gagal memuat konfigurasi, tidak terhubung dengan server",
    mailSentText:
      "Kami telah mengirim link untuk mengatur ulang kata sandi anda melalui email, silahkan buka kotak masuk email anda",
    userNotFound: "Pengguna yang menggunakan email ini tidak terdaftar",
    wrongEmailOrPassword: "E-mail atau kata sandi salah",

    //form errors
    "cannot be blank": "Tidak boleh kosong.",
    "must not be greater than net price": "Tidak boleh melebihi harga net",

    //placeholder
    Placeholder: "Sementara",
    imageDescription: "Deskripsi gambar",
    pleaseFillImage: "Mohon isi deskripsi gambar",

    //actions
    action_draft: "Draf",
    action_confirm: "Konfirmasi",
    action_confirm_in: "Konfirmasi Masuk",
    action_confirm_out: "Konfirmasi Keluar",
    action_cancel: "Batalkan",
    action_print: "Cetak",
    download: "Unduh",
    discardChanges: "Abaikan Perubahan",

    //date filter
    dateStart: "Tanggal Awal",
    dateStop: "Tanggal Akhir",

    // account renderer
    renderLevel: "Tingkat",

    // browser exporting
    "export.startDate": "Tanggal Mulai",
    "export.endDate": "Tanggal Akhir",
    "export.allTime": "Semua Waktu",
    "export.state": "Status",

    // additional Widget
    Dashboard: "Dasbor",
    chooseWidget: "Pilih Widget",
    Close: "Tutup",
    searchNotFound: "Maaf, pencarian tidak ditemukan.",

    //detailed item picker
    itemSearch: "Pencarian Barang",
    itemName: "Nama Barang",
    totalStock: "Total Stok",
    itemUnitName: "Satuan",
    category: "Kategori",
    price: "Harga",
    refreshingCache: "Memuat Cache...",

    orderTracking: "Lacak Pesanan",

    //Chat
    leaveChat: "Apakah Anda yakin ingin meninggalkan obrolan ini?",

    //midtrans
    midtransPending:
      "Silakan untuk menyelesaikan pembayaran sesuai metode yang dipilih",
    midtransUserCancelled: "Pembayaran dibatalkan",
    midtransError:
      "Terjadi kesalahan saat mengontak server midtrans, silakan coba lagi nanti. Hubungi administrator untuk info lebih lanjut",

    //reportConfig
    columnConfig: "Pengaturan Kolom",

    //RequestApproval
		"RequestApproval":"Persetujuan Permintaan",
		"RequestApproval.active":"Aktif",
		"RequestApproval.waiting":"Menunggu",
		"RequestApproval.history":"Riwayat",
		"RequestApproval.confirm":"Konfirmasi",
		"RequestApproval.allClear":"Selesai",
		"RequestApproval.yourRequests":"Permintaan Anda",
		"RequestApproval.noRequest":"Belum ada permintaan baru saat ini",
		"RequestApproval.processed":"Diproses",
		"RequestApproval.pending":"Pending",
		"RequestApproval.item.request_price_save":"Ubah harga %s dari %oldPrice ke %newPrice",
		"RequestApproval.item.request_prices_bulk_save":"Ubah beberapa harga barang ke %newPrice",
		"RequestApproval.complete.confirm":"Permintaan berhasil dikonfirmasi",
		"RequestApproval.complete.reject":"Permintaan berhasil ditolak",

    //Timeslot
    "Timeslot.day":"Hari",
    "Timeslot.allDay":"Sepanjang Hari",
    "Timeslot.startHour":"Jam Mulai",
    "Timeslot.endHour":"Jam Akhir",
    "Timeslot.error.hourError":"Jam awal tidak boleh melebihi jam akhir",

    //custom modules
    "module.quantity": "Jumlah",
    "module.price": "Harga",
    "module.createNew": "Buat Baru...",
    "module.createX": 'Buat "%s"',
    "module.rack": "Rak",
    "module.minimumPrice": "Harga Min.",
    

    ...(renderedConfig.accounting ? AccountingTranslation["id"] : {}),
    ...(renderedConfig.property ? PropertyTranslation["id"] : {}),
    ...(renderedConfig.hr ? HrTranslation["id"] : {}),
    ...(renderedConfig.shop ? ShopTranslation["id"] : {}),
    ...(renderedConfig.base ? BaseTranslation["id"] : {}),
    ...(renderedConfig.web ? WebTranslation["id"] : {}),
    ...(renderedConfig.freight ? FreightTranslation["id"] : {}),
    ...(renderedConfig.saasCenter ? SaasTranslation["id"] : {}),
    ...(renderedConfig.realEstate ? realEstateTranslation["id"] : {}),
    ...(renderedConfig.themePark ? themeParkTranslation["id"] : {}),
    ...(renderedConfig.bakery ? BakeryTranslation["id"] : {}),
    ...(renderedConfig.restaurant ? RestaurantTranslation["id"] : {}),
  },
  en: {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",

    //user menu
    changePassword: "Change Password",
    logout: "Logout",
    "logout.confirmationText": "Are you sure want to logout?",
    unsavedChanges:"Ignore Unsaved Changes",
    "unsavedChanges.confirmationText":"There are some unsaved changes on your form, do you want to ignore these changes and continue?",

    //languages
    languages: "Languages",
    English: "English",
    Indonesian: "Indonesian",

    //login
    email: "Email",
    login: "Login",
    loginWithFacebook: "Login with Facebook",
    loginWithGoogle: "Login with Google",
    password: "Password",
    forgotPassword: "Forgot Password",
    hidePassword: "Hide Password",
    peekPassword: "Peek Password",

    //password recovery
    passwordRecovery: "Recover your Password",
    helperTextPasswordRecovery:
      "Enter your E-mail address. We will send you a link to reset your password",
    helperTextEmailSent:
      "We have sent you an email containing the link. Please check your email",
    send: "Send",
    sendPreviousMessage:"Send Previous Message",

    //password reset
    resetYourPassword: "Reset your password",
    newPassword: "New Password",
    oldPassword: "Old Password",
    confirmPassword: "Confirm Password",
    oldPasswordIsWrong: "Old password is wrong",
    passwordsAreDifferent: "Passwords are different",
    reset: "Reset",
    updatePassword: "Update Password",
    "passwordUpdate.success": "Password updated successfully",

    //user page
    profile: "Profile",
    viewProfile: "View Profile",
    subsription: "Subscription",
    cancelSubscription: "Cancel Subscription",
    editProfile: "Edit Profile",
    ignoreChanges: "Ignore Changes",
    ignoreChangesText: "Do you want to ignore changes you made?",
    "profileUpdate.success": "Profile updated successfully",

    //notifications
    notifications: "Notifications",
    seeAllNotifications: "See All Notifications",
    allNotifications: "All Notification",
    waitASec: "Wait a sec",
    noNotification: "No Notification",

    //browsetable & forms
    create: "Create",
    search: "Search",
    searchPlace: "Search Place",
    select: "Select",
    clear: "Clear",
    refreshData: "Refresh data",
    filterData: "Filter Data",
    applyFilter: "Apply Filter",
    enableFiltering: "Enable Filtering",
    disableFiltering: "Disable Filtering",
    deleteData: "Delete Data",
    delete: "Delete",
    printData: "Print Data",
    actions: "Actions",
    cancel: "Cancel",
    close: "Close",
    save: "Save",
    update: "Update",
    allData: "All Data",
    viewData: "View Data",
    editData: "Edit Data",
    createData: "Create New",
    enableEditing: "Edit Data",
    createNew: "Create New",
    addNew: "Add New",
    addMediaUrl: "Add Video URL",
    mediaUrl: "Video URL",
    image: "Image",
    empty: "Empty",
    done: "Done",
    noRowsFound: "No Rows Found",
    next: "Next",
    prev: "Previous",
    back: "Kembali",
    browsePage: "Page",
    browseOf: "of",
    browseRows: "Rows",
    clearImageNotice: "Are you sure want to clear all images?",
    "are you sure": "Are you sure?",
    "are you sure want to perform":
      "Are you sure want to perform this action to selected data?",
    "are you sure want to delete": "Are you sure want to delete?",
    "are you sure want to sync": "Are you sure want to sync data?",
    thisItem: "this item",
    theseItems: "these items",
    pleaseFillIn: "Please fill in",
    pleaseRecheckDate: "Please recheck date",
    pleaseSelect: "Select",
    pickFile: "Pick file",
    pickFrom: "Pick from",
    pickColor: "Pick Color",
    pick: "Pick",
    fullScreen: "Fullscreen",
    changeFile: "Change file",
    removeFile: "Remove file",
    noFile: "No file",
    fileIsTooBig: "Maximum file size is 2MB",
    clickToChange: "Click to change...",
    add: "Add",
    confirmation: "Confirmation",
    confirm: "Confirm",
    yes: "Yes",
    and: "and",
    no: "No",
    all: "All",
    mustBeFilled: "Must be filled",
    checkAll: "Check All",
    uncheckAll: "Uncheck All",
    selected: "Selected",
    notANumber: "Not a number",
    "Data Created": "Data Created",
    "Data Updated": "Data Updated",
    "Data Deleted": "Data Deleted",
    Download: "Download",
    export: "Export",
    sync: "Synchronize",
    share: "Share",
    copyLink: "Copy Link",
    copyToClipboard:"Copy to clipboard",
    "linkCopy.success": "Link copied to the clipboard",
    "copy.success": "Text copied to the clipboard",
    reportType: "File Type",
    excel: "Excel",
    pdf: "PDF",
    loading: "Loading",
    saving: "Saving",
    uploading: "Uploading ...",
    uploadError: "Error when uploading file, please try again",
    errorMaxFileSize: "Maximum file size is %s",
    saved: "Saved",
    page: "Page",
    pleaseWait: "Please Wait",
    change: "Change",
    pleaseCheckFirst: "Please check first data before clicking this action",
    serverMaintenance: "Server Maintenance, please try again later",
    domainNotFound: "Sorry, we can't seem to find the page you're looking for.",
    invalidHex: "Invalid Hex Color",
    bulkActionSuccess: "Action Done :",
    actionSuccess: "Success",
    actionFailed: "Failed",
    rebuildData: "Rebuild Data",
    rebuildWarning:
      "Warning : If you selected rebuild data, all of your data that related to blog, categories, items, etc. will be deleted and rebuild based on current configuration",
    syncCompleted: "Synchronization Completed",
    syncFailed: "Synchronization Failed, Please contact your administrator",
    addedToList: "Added to list",
    print: "Print",
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time",
    applySettings: "Apply Settings",

    //attachment
    cropImage: "Crop Image",
    preview: "Preview",
    undoCrop: "Undo Crop",
    doneCrop: "Done Cropping",

    //many2one
    typeToSearch: "Search...",
    noOptionSelected: "No Option Selected",
    browse: "Browse",
    //--one2many
    deleteRow: "Delete Row",
    //--status
    currentStatus: "Current Status",
    none: "None",
    undefined: "Undefined",
    notSet: "Undefined",
    unknown: "Unknown",
    time: "Time",
    lockData: "Lock Value",
    unlockData: "Unlock Value",
    configuration: "Configuration",
    viewAll: "View All",
    noTitle: "No Title",
    noDescription: "No Description",
    noDataFound: "No data found",
    noData:"No Data",
    noDataFoundText:
      "You may clicked a wrong link or expired, please contact the administrator for more info",
    minChar: "%s characters minimum",
    charsLeft: "%s characters left",
    charsComplete: "Characters fullfilled",

    //errors Description
    pleaseTryAgain: "Please Try Again",
    inputError: "There's something wrong in your form, please check again",
    serverNotFound: "Server not found, please contact the system administrator",
    serverError:
      "The server currently had an error, please contact the system administator",
    serverUnavailable:
      "Sorry, the server currently not available. please try again later",
    configNotSavedServerError:
      "Configuration can't be saved, not connected with server",
    configNotLoadedServerError:
      "Failed loading configuration, not connected with server",
    mailSentText:
      "We have sent you an email containing the link. Please check your email",
    userNotFound: "User with this email address does not exist",
    wrongEmailOrPassword: "Wrong e-mail or password",

    //form errors
    "cannot be blank": "Cannot be blank.",
    "must not be greater than net price":
      "Must not be greater than nett price.",

    //placeholder
    Placeholder: "Placeholder",
    imageDescription: "Image description",
    pleaseFillImage: "Please fill image description",

    //actions
    action_draft: "Draft",
    action_confirm: "Confirm",
    action_confirm_in: "Confirm In",
    action_confirm_out: "Confirm Out",
    action_cancel: "Cancel",
    action_print: "Print",
    download: "Download",
    discardChanges: "Discard Changes",

    //date filter
    dateStart: "Date Start",
    dateStop: "Date End",

    // account renderer
    renderLevel: "Level",

    // browser exporting
    "export.startDate": "Start Date",
    "export.endDate": "End Date",
    "export.allTime": "All Time",
    "export.state": "Status",

    // additional Widget
    Dashboard: "Dashboard",
    chooseWidget: "Choose Widget",
    Close: "Close",
    searchNotFound: "Sorry, no search was found.",

    //detailed item picker
    itemSearch: "Item Search",
    itemName: "Item Name",
    totalStock: "Total Stock",
    itemUnitName: "Unit",
    category: "Category",
    price: "Price",
    refreshingCache: "Refreshing Cache...",

    //chat
    leaveChat: "Are you sure to leave this chat?",
    orderTracking: "Order Tracking",

    //midtrans
    midtransPending: "Please finish your payment with selected payment method",
    midtransUserCancelled: "Payment cancelled",
    midtransError:
      "Error while contacting midtrans server, please try again later or contact the administrator for further information",

    //reportConfig
    columnConfig: "Column Settings",

    //RequestApproval
		"RequestApproval":"Request Approval",
		"RequestApproval.active":"Active",
		"RequestApproval.waiting":"Waiting",
		"RequestApproval.history":"History",
		"RequestApproval.confirm":"Confirm",
		"RequestApproval.allClear":"All Clear",
		"RequestApproval.noRequest":"There's no new requests at this time",
		"RequestApproval.yourRequests":"Your Requests",
		"RequestApproval.processed":"Processed",
		"RequestApproval.pending":"Pending",
		"RequestApproval.item.request_price_save":"Change %s price from %oldPrice to %newPrice",
		"RequestApproval.item.request_prices_bulk_save":"Change several items' price to %newPrice",
		"RequestApproval.complete.confirm":"Request successfully confirmed",
		"RequestApproval.complete.reject":"Request successfully rejected",

    //Timeslot
    "Timeslot.day":"Day",
    "Timeslot.allDay":"All Day",
    "Timeslot.startHour":"Start Hour",
    "Timeslot.endHour":"End Hour",
    "Timeslot.error.hourError":"Start hour cannot exceed the end hour",

    //custom modules
    "module.quantity": "Qty",
    "module.price": "Price",
    "module.createNew": "Create New...",
    "module.createX": 'Create "%s"',
    "module.rack": "Rack",
    "module.minimumPrice": "Min. Price",

    ...(renderedConfig.accounting ? AccountingTranslation["en"] : {}),
    ...(renderedConfig.property ? PropertyTranslation["en"] : {}),
    ...(renderedConfig.hr ? HrTranslation["en"] : {}),
    ...(renderedConfig.shop ? ShopTranslation["en"] : {}),
    ...(renderedConfig.base ? BaseTranslation["en"] : {}),
    ...(renderedConfig.web ? WebTranslation["en"] : {}),
    ...(renderedConfig.freight ? FreightTranslation["en"] : {}),
    ...(renderedConfig.saasCenter ? SaasTranslation["en"] : {}),
    ...(renderedConfig.realEstate ? realEstateTranslation["en"] : {}),
    ...(renderedConfig.themePark ? themeParkTranslation["en"] : {}),
    ...(renderedConfig.bakery ? BakeryTranslation["en"] : {}),
    ...(renderedConfig.restaurant ? RestaurantTranslation["en"] : {}),
  },
};

export default Translations;
