import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import ReportIcon from "@material-ui/icons/Report";
import Tooltip from "@material-ui/core/Tooltip";
import { isNumeric } from "framework/utils/NumericChecker";
import _ from "lodash";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
// const KEY_F2 = 113;

export default class Number extends Component {
  constructor(props) {
    super(props);
    this.numberInput = React.createRef();
    this.state = {
      field: props.field,
      value: props.value ? props.value : 0.0,
      highlightAllOnFocus: true,
      _error: "",
    };
    this.delayedCallback = _.debounce(this.applyChange, props.delayTime || 500);
    this.numberRef = React.createRef();
    this.maskRef = React.createRef();

    if (this.props.renderMode === "gridRenderer") {
      let currentRowIndex = props.rowIndex;
      if (
        typeof props.cellErrors === "object" &&
        props.cellErrors !== null &&
        currentRowIndex in props.cellErrors
      ) {
        if (props.field in props.cellErrors[currentRowIndex]) {
          let errorText = _.get(props.cellErrors[currentRowIndex], props.field);
          if (props.value !== 0 && errorText === "cannot be blank") {
            this.state._error = "";
          } else {
            this.state._error = tl(Cookies.get("lang"), errorText);
          }
        }
      }
    }

    if (this.props.renderMode === "gridEditor") {
      if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
        this.state.value = 0;
      } else if (props.charPress) {
        this.state.value = isNumeric(props.charPress) ? props.charPress : "";
        this.state.highlightAllOnFocus = false;
      } else {
        this.state.value = props.value;
      }
    }
  }

  applyChange = (value) => {
    let newValue = isNumeric(value) ? value : 0;
    if (this.props.renderMode === "browserFilter") {
      this.props.onChange(parseFloat(newValue));
    } else {
      this.props.onChange(this.props.field, parseFloat(newValue));
    }
  };

  componentWillReceiveProps(nextProps) {
    let valueToSet = null;
    if (nextProps.value === 0 && this.state.value === "") {
      valueToSet = "";
      this.numberRef.current.value = "0";
    } else {
      valueToSet = isNumeric(nextProps.value) ? nextProps.value : 0;
    }
    this.setState({ value: valueToSet }, ()=>{
      if (this.numberRef && this.numberRef.current) {
        if (nextProps.renderMode === "formView") {
          this.numberRef.current.value = valueToSet.toLocaleString("en-US");
        } else {
          this.numberRef.current.value = valueToSet;
        }
      }
    });
  }

  handleChange = (event) => {
    let { value } = event.target;
    let newValue = 0;
    if (value === "") {
      newValue = 0;
    } else {
      newValue = isNumeric(value) ? parseFloat(value) : 0;
    }
    if (this.numberRef && this.numberRef.current) {
      this.numberRef.current.value = newValue;
    }
    this.setState({ newValue });
    this.delayedCallback(newValue);
  };

  componentDidMount() {
    if (this.props.renderMode === "gridEditor") {
      this.numberInput.current.addEventListener("keydown", this.onKeyDown);
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      event.stopPropagation();
    }
  }

  //use param argument to access grid state (i.e. afterGuiAttached(param) {})
  afterGuiAttached() {
    if (this.props.renderMode === "gridEditor") {
      if (this.numberInput) {
        this.numberInput.current.focus();
        if (this.state.highlightAllOnFocus) {
          this.numberInput.current.select();
        }
      }
    }
  }

  getValue() {
    return isNumeric(this.numberInput.current.value)
      ? parseFloat(this.numberInput.current.value)
      : 0;
  }

  isPopup() {
    return false;
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (this.props.onEnterPressed && this.props.renderMode !== "formView") {
        let refValue = "0";
        if (this.numberRef) {
          refValue = this.numberRef.current.value;
        }
        this.props.onEnterPressed(parseFloat(refValue));
      }
    } else if ([38, 40].includes(e.keyCode) && this.props.disableUpDownKey) {
      e.preventDefault();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  };

  triggerFocus = () => {
    this.numberRef.current.focus();
    this.numberRef.current.select();
  };

  triggerBlur = () => {
    this.numberRef.current.blur();
  };

  triggerSetValue = (value) => {
    this.setState({
      value,
    });
  };

  handleFocus = () => {
    if (this.props.autoSelectWhenFocus) {
      this.numberRef.current.select();
    }
  };

  render() {
    let labelText = null;
    if (this.props.label) {
      labelText = this.props.label;
    } else {
      labelText = tl(Cookies.get("lang"), this.props.field);
    }

    let renderedValue = null;
    if (this.props.renderMode === "formView") {
      renderedValue = this.state.value.toLocaleString("en-US", {
        minimumFractionDigits: 0,
      });
    } else {
      renderedValue = this.state.value;
    }

    let InputComponent = null;
    if (
      ["formCreate", "formEdit", "formView"].includes(this.props.renderMode)
    ) {
      InputComponent = (
        <div>
          {!this.props.disableLabel && <InputLabel
            shrink
            className={`input-label ${this.props.white ? "white" : ""}`}
          >
            {labelText}
            {this.props.required ? "*" : ""}
          </InputLabel>}
          <div
            className={`basic-input-container ${
              this.props.renderMode !== "formView" &&
              this.props.editable === false &&
              this.props.washedOff !== false &&
              "input-disabled"
            }`}
          >
            <input
              type={this.props.renderMode === "formView" ? "text" : "number"}
              disabled={
                this.props.renderMode === "formView" ||
                this.props.editable === false ||
                this.props.washedOff === false
              }
              autoComplete="off"
              className={"basic-inner-input-base"}
              defaultValue={renderedValue}
              ref={this.numberRef}
              id={this.props.field}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              placeholder={
                this.props.placeholder &&
                this.props.editable !== false &&
                this.props.washedOff !== false
                  ? this.props.placeholder
                  : ""
              }
              style={{ border: "none", margin: "0px 10px" }}
            />
            <Tooltip
              style={{ display: this.props.error ? "inline-block" : "none" }}
              title={this.props.error ? this.props.error : ""}
            >
              <ReportIcon className="basic-input-error-icon" />
            </Tooltip>
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridEditor") {
      InputComponent = (
        <div>
          <div className="basic-input-container">
            <input
              className="grid-editor-number"
              type="number"
              defaultValue={this.state.value}
              ref={this.numberInput}
            />
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridRenderer") {
      let value = isNumeric(this.state.value) ? this.state.value : 0;
      if (this.props.numberFormat === "currency") {
        if (this.props.currency) {
          value = value.toLocaleString("en-US", {
            style: "currency",
            currency: this.props.currency,
            maximumFractionDigits: 2,
          });
        }
      } else if (this.props.numberFormat === "thousandSeparator") {
        value = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(value);
      }
      InputComponent = (
        <div className="grid-renderer-container">
          <div
            className={`grid-renderer-content ${
              this.state.value === null && this.props.required === true
                ? "value-null"
                : ""
            }`}
          >
            {value}
          </div>
          <Tooltip
            style={{ display: this.state._error ? "inline-block" : "none" }}
            title={this.state._error ? this.state._error : ""}
          >
            <ReportIcon className="basic-grid-error-icon" />
          </Tooltip>
        </div>
      );
    } else if (this.props.renderMode === "browserFilter") {
      InputComponent = (
        <div>
          <div className="basic-input-container">
            <input
              type="number"
              defaultValue={this.props.value}
              onChange={(event) => this.handleChange(event)}
              className="filter-text-input"
            />
          </div>
        </div>
      );
    }
    return InputComponent;
  }
}
