import React, { Component } from "react";
import Button from "components/CustomButtons/Button.jsx";
import {
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  CircularProgress,
  withStyles
} from "@material-ui/core";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";

const style = {
  customPaper: {
    overflowY: "visible",
  },
  loadingContainer:{
    position:'absolute',
    zIndex:"10",
    top:"0px",
    left:'0px',
    width:"100%",
    height:"100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:"rgba(255,255,255,0.5)"
  }
};

class ConfirmDialog extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== undefined && nextProps.open === true) {
      document.addEventListener("keydown", this.handleKeyDown);
    } else {
      document.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && this.props && this.props.handleYes) {
      this.props.handleYes();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog"
        {...this.props.maxWidth?{maxWidth:this.props.maxWidth}:{}}
        {...this.props.fullWidth?{fullWidth:this.props.fullWidth}:{}}
        {...(this.props.overflow
          ? {
              classes: {
                paper: classes.customPaper,
              },
            } 
          : {})}
      >
        <DialogTitle className="dialog-title" id="alert-dialog-title">
          {this.props.title}
        </DialogTitle>
        <DialogContent
          className="dialog-content"
          style={this.props.overflow ? { overflowY: "visible" } : {}}
        >
          {(this.props.loading && this.props.showSpinner) && <div className={classes.loadingContainer}>
            <CircularProgress/>
          </div>}
          <DialogContentText id="alert-dialog-description">
            {this.props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions">
          {this.props.handleNo && (
            <Button
              color="transparent"
              key="no"
              size="sm"
              className="rounded-button negative"
              disabled={this.props.loading}
              onClick={this.props.handleNo}
            >
              {this.props.labelNo
                ? this.props.labelNo
                : tl(Cookies.get("lang"), "no")}
            </Button>
          )}
          {this.props.handleYes && (
            <Button
              color="success"
              key="yes"
              size="sm"
              className="rounded-button"
              onClick={this.props.handleYes}
              disabled={this.props.loading}
              autoFocus
            >
              {this.props.labelYes
                ? this.props.labelYes
                : tl(Cookies.get("lang"), "yes")}
            </Button>
          )}
        </DialogActions>
        <div className="dialog-filler" />
      </Dialog>
    );
  }
}

export default withStyles(style)(ConfirmDialog);
