import React from "react";
import { Switch, Route } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import Config from "company/Config";
import DebugTool from "framework/inputs/DebugTool";
import SpotlightSearch from "../framework/components/SpotlightSearch";

//redux
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

//styles
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import cx from "classnames";

//icons
import Warning from "@material-ui/icons/Warning";
import Check from "@material-ui/icons/Check";

//images
import image from "assets/img/sidebar-2.jpg";
import AdminLogo from "company/public/images/svg/logo.svg";

//Chat App
import ChatApp from "../framework/chat/ChatApp";

//routes
import AccountingRoutes from "app/modules/Accounting/Routes";
import PropertyRoutes from "app/modules/Property/Routes";
import HrRoutes from "app/modules/Hr/Routes";
import ShopRoutes from "app/modules/Shop/Routes";
import BaseRoutes from "app/modules/Base/Routes";
import WebRoutes from "app/modules/Web/Routes";
import FreightRoutes from "app/modules/Freight/Routes";
import SaasRoutes from "app/modules/Saas/Routes";
import RealEstateRoutes from "app/modules/RealEstate/Routes";
import ThemeParkRoutes from "app/modules/ThemePark/Routes";
import BakeryRoutes from "app/modules/Bakery/Routes";
import RestaurantRoutes from "app/modules/Restaurant/Routes";
import ConfirmDialog from "framework/components/ConfirmDialog";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: "blue",
      bgColor: "black",
      hasImage: true,
      fixedClasses: "dropdown",
      accessRightsLoaded: false,
    };
    this.userData = null;
    this.resizeFunction = this.resizeFunction.bind(this);

    if (!Config.saasConfig) {
      if (!Cookies.get("accessToken")) {
        if (Cookies.get("refreshToken")) {
          axios
            .post(this.props.Session.host + "/api/oauth/token", {
              refresh_token: Cookies.get("refreshToken"),
            })
            .then((response) => {
              Cookies.set("accessToken", response.data.accessToken, {
                path: "/",
                expires: moment.unix(response.data.atExpiredAt).toDate(),
              });
              Cookies.set("refreshToken", response.data.refreshToken, {
                path: "/",
                expires: moment.unix(response.data.rtExpiredAt).toDate(),
              });
            })
            .catch((error) => {
              if (error) {
                this.props.history.push("/auth/login");
              }
            });
        } else {
          if (props.location.pathname !== "/admin/dashboard") {
            let encodedPath = encodeURIComponent(this.props.location.pathname);
            this.props.history.push("/auth/login?redirect=" + encodedPath);
          } else {
            this.props.history.push("/auth/login");
          }
        }
      }
    }

    if (!Cookies.get("username") && !Config.saasConfig) {
      axios
        .get(this.props.Session.host + "/user", {
          headers: { Authorization: "Bearer " + Cookies.get("accessToken") },
        })
        .then((res) => {
          Cookies.set("username", res.data.name, {
            path: "/",
            expires: moment().add(2, "days").toDate(),
          });
        });
    }
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
    if (!Config.saasConfig) {
      if (this.props.Session.accessRights.length === 0) {
        axios
          .post(
            this.props.Session.host + "/api/user/get/accessRights",
            {},
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("accessToken"),
              },
            },
          )
          .then((response) => {
            this.props.setAccessRights(
              response.data.accessRights,
              response.data.menus,
            );
            this.setState({ accessRightsLoaded: true });
          })
          .catch((error) => {
            if (error) {
              Cookies.remove("accessToken", { path: "/" });
              Cookies.remove("refreshToken", { path: "/" });
              Cookies.remove("username", { path: "/" });
              if (this.props.location.pathname !== "/admin/dashboard") {
                let encodedPath = encodeURIComponent(
                  this.props.location.pathname,
                );
                this.props.history.push("/auth/login?redirect=" + encodedPath);
              } else {
                this.props.history.push("/auth/login");
              }
            }
          });
      }
    } else {
      //redirection safeguard
      if (!Cookies.get("accessToken")) {
        if (Cookies.get("refreshToken")) {
          axios
            .post(this.props.Session.host + "/api/oauth/token", {
              refresh_token: Cookies.get("refreshToken"),
            })
            .then((response) => {
              Cookies.set("accessToken", response.data.accessToken, {
                path: "/",
                expires: moment.unix(response.data.atExpiredAt).toDate(),
              });
              Cookies.set("refreshToken", response.data.refreshToken, {
                path: "/",
                expires: moment.unix(response.data.rtExpiredAt).toDate(),
              });
            })
            .catch((error) => {
              if (error) {
                this.props.history.push("/auth/login");
              }
            });
        } else {
          if (this.props.location.pathname !== "/admin/dashboard") {
            let encodedPath = encodeURIComponent(this.props.location.pathname);
            this.props.history.push("/auth/login?redirect=" + encodedPath);
          } else {
            this.props.history.push("/auth/login");
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleImageClick = (image) => {
    this.setState({ image: image });
  };

  handleColorClick = (color) => {
    this.setState({ color: color });
  };

  handleBgColorClick = (bgColor) => {
    this.setState({ bgColor: bgColor });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }

  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  closeNotification() {
    this.props.setNotification(
      false,
      this.props.View.notification.message,
      this.props.View.notification.color,
    );
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
      });
    let renderedConfig = null;
    if (Config.saasConfig) {
      renderedConfig = this.props.Session;
    } else {
      renderedConfig = Config;
    }
    let renderedRoutes = [
      ...routes,
      ...(renderedConfig.saasCenter ? SaasRoutes : []),
      ...(renderedConfig.web ? WebRoutes : []),
      ...(renderedConfig.shop ? ShopRoutes : []),
      ...(renderedConfig.accounting ? AccountingRoutes : []),
      ...(renderedConfig.property ? PropertyRoutes : []),
      ...(renderedConfig.hr ? HrRoutes : []),
      ...(renderedConfig.base ? BaseRoutes : []),
      ...(renderedConfig.freight ? FreightRoutes : []),
      ...(renderedConfig.realEstate ? RealEstateRoutes : []),
      ...(renderedConfig.themePark ? ThemeParkRoutes : []),
      ...(renderedConfig.bakery ? BakeryRoutes : []),
      ...(renderedConfig.restaurant ? RestaurantRoutes : []),
    ];

    let AdminPage = (
      <div className={classes.wrapper}>
        {Config.debug && <DebugTool />}
        <Sidebar
          routes={renderedRoutes}
          logoText={""}
          logo={AdminLogo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div
          className={mainPanel}
          ref="mainPanel"
          style={
            this.props.location.pathname === "/admin/dashboard"
              ? { overflow: "hidden" }
              : {}
          }
        >
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {this.getRoute() ? (
            <div className={classes.content+(this.props.location.pathname === "/admin/dashboard"?" dashboard":"")}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(renderedRoutes)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(renderedRoutes)}</Switch>
            </div>
          )}
        </div>
        <Snackbars
          place="br" //bottom right (snackbar notification location)
          color={
            this.props.View.notification.color === ""
              ? "info"
              : this.props.View.notification.color
          }
          icon={
            this.props.View.notification.color === "success" ? Check : Warning
          }
          message={tl(
            Cookies.get("lang"),
            this.props.View.notification.message,
          )}
          open={this.props.View.notification.isOpen}
          closeNotification={() => this.closeNotification()}
          close
        />
        <ChatApp />
        <SpotlightSearch routes={renderedRoutes} />
        <ConfirmDialog
          open={this.props.View.unsavedConfirm.open}
          title={tl("unsavedChanges")}
          content={tl("unsavedChanges.confirmationText")}
          handleYes={()=>{
            this.props.setUnsavedConfirmClose()
            this.props.View.unsavedConfirm.handleYes()
          }}
          handleNo={this.props.setUnsavedConfirmClose}
        />
      </div>
    );

    if (
      Config.saasConfig &&
      this.props.Session.isConfigLoaded &&
      this.props.Session.accessRightsLoaded
    ) {
      //the config and accessRights must be loaded if using remote configuration (Saas)
      return AdminPage;
    } else if (!Config.saasConfig && this.state.accessRightsLoaded) {
      //the accessRight must be loaded if using local configuration
      return AdminPage;
    } else {
      //otherwise it will show blank page
      return null;
    }
  }
}

// Dashboard.propTypes = {
// 	classes: PropTypes.object.isRequired
// };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(appStyle)(withRouter(Dashboard)));
