export const ReactSelectStyle = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f5f5f5",
    border: "transparent",
    borderRadius: "20px",
    boxShadow: "none",
    minHeight: "1px",
    height: "35px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 14px",
  }),
  input: (provided) => ({
    ...provided,
    marginLeft: "0px",
    fontFamily: "Quicksand",
    "& div": {
      "& input": {
        fontFamily: "Quicksand",
      },
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "Quicksand",
    cursor: "pointer",
    color: state.isSelected ? "#fff" : "#000",
    backgroundColor: state.isSelected
      ? state.isFocused
        ? "#144785" //darker blue
        : "#2168bf" //normal blue
      : state.isFocused
        ? "#eee"
        : "#fff",
    ":hover": {
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#2168bf" : "#eee",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Quicksand",
    color: "#aaa",
    whiteSpace:"nowrap",
    overflow:"hidden",
    textOverflow:"ellipsis",
    maxWidth:"calc(100% - 30px)"
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: "0px",
    fontFamily: "Quicksand",
    color: "#000",
    top: "unset",
    transform: "unset",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
    margin: "0px 5px 0px 5px",
    color: "#dedede",
    transition: "0.3s",
    ":hover": { color: "#333" },
  }),
};

export const ReactSelectManyToMany = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f5f5f5",
    border: "transparent",
    borderRadius: "20px",
    boxShadow: "none",
  }),
  input: (provided) => ({
    ...provided,
    marginLeft: "10px",
    fontFamily: "Quicksand",
    "& div": {
      "& input": {
        fontFamily: "Quicksand",
      },
    },
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Quicksand",
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: "10px",
    fontFamily: "Quicksand",
    color: "#aaa",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: "10px",
    fontFamily: "Quicksand",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
    margin: "3px 5px 0px 5px",
    color: "#dedede",
    transition: "0.3s",
    ":hover": { color: "#333" },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
    padding: "0px 5px 0px 5px",
    fontFamily: "Quicksand",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "rgba(0,0,0,0.2)",
    borderRadius: "50%",
    transition: "0.2s",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#fff",
      color: "#c62828",
    },
  }),
};
