import React, { Component } from 'react'
import { ButtonBase, withStyles } from '@material-ui/core'
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BackIcon from "@material-ui/icons/ArrowBack"

class AdaptiveBreadcrumb extends Component {

    handleBack = (payload) => {
        if (this.props.onBack && payload) {
            this.props.onBack(payload)
        }
    }

    render() {
        const {classes, View} = this.props
        let lastActivity = View.activityStack[View.activityStack.length - 1]
        let backTo = View.activityStack[View.activityStack.length - 2]
        return (
        <div className={classes.adaptiveBreadcrumb}>
            <div className="defaultBc">
                <Breadcrumbs
                    separator={
                        <NavigateNextIcon
                        className="breadcrumb-separator"
                        fontSize="small"
                        />
                    }
                    aria-label="Breadcrumb"
                >
                    {this.props.View.activityStack.map((payload, i) => (
                        <Link
                        onClick={() => this.handleBack(payload)}
                        className="breadcrumb-item"
                        key={i}
                        >
                        {payload.name.length > 30 && i > 0
                            ? payload.name.substring(0, 30) + "..."
                            : payload.name}
                        </Link>
                    ))}
                </Breadcrumbs>
            </div>
            <div className="mobileBc">
                {lastActivity && <div className={'mobileTitle '+(backTo?"canBack":"")}>
                    {backTo && <ButtonBase className="backButton" onClick={() => this.handleBack(backTo)}>
                        <BackIcon/>
                    </ButtonBase>}
                    <Link
                        className="breadcrumb-item-mobile"
                    >
                        {lastActivity?.name || ""}
                    </Link>
                </div>}
            </div>
            
        </div>
        )
    }
}

const style = {
    adaptiveBreadcrumb:{
        "&>.defautlBc":{
            display:"block"
        },
        "&>.mobileBc":{
            display:'none'
        },
        "@media(max-width:560px)":{
            "&>.defaultBc":{
                display:"none"
            },
            "&>.mobileBc":{
                display:'block',
                "&>.mobileTitle":{
                    width:'calc(100% - 150px)',
                    display: 'flex',
                    position:'relative',
                    height:"20.5",
                    paddingLeft:"0px",
                    transition:"0.3s ease-out",
                    "&.canBack":{
                        paddingLeft:'50px',
                    },
                    "&>.backButton":{
                        width:"41px",
                        height:"41px",
                        backgroundColor:'#fff',
                        border:'1px solid #ddd',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius:"50%",
                        position:"absolute",
                        top:"-12px",
                        left:"0px",
                        zIndex:"2",
                        "& svg":{
                            width:"18px"
                        }
                    }
                }
            },
        }
    }
}

export default withStyles(style)(AdaptiveBreadcrumb)