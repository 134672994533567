import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'
import ReportDownloadButton from "framework/components/ReportDownloadButton"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'


class InventoryConversionAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: ""
		}
	}

	handlePrint = () => {
		let record = this.props.record
		let format = record.reportType === "excel"?".xlsx":".pdf"
		this.setState({isLoading:true})
		API("download","inventory_adjustment/report_print",record)
		.then(response=>{
			if (response) {
				this.props.setNotification(true, "InventoryAdjustmentReport.printRequested", "success")
				setTimeout(()=>{
					this.props.setNotification(false, "InventoryAdjustmentReport.printRequested", "success")
					this.setState({isLoading:false})
				}, 3000)
			}
		})
		.catch(error=>{
			this.setState({isLoading:false})
			if(error.response.status === 401){
				this.props.error(error.response.data.fields)
			}
		})
	}
	
	render() {
		let buttons = []
		if (can("inventory_adjustment.report_print")) {
			buttons.push(
				<Button 
					key="print-pdf-preview"
					size="sm"
					className="rounded-button"
					disabled={this.state.isLoading}  
					onClick={()=>this.handlePrint()}>
					{tl(Cookies.get("lang"),"print")}
				</Button>
			)
		}
		buttons.push(<ReportDownloadButton
			record={this.props.record}
			model="inventory_adjustment/report"
		/>)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(InventoryConversionAction)
