import React, { Component } from "react";
import PrintIcon from "@material-ui/icons/Print";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "framework/containers/Modal";
import API from "framework/utils/API";

export default class PDFDownloadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dialog: {
        open: false,
        payload: null,
      },
    };
    this.PreviewComponent = import("framework/containers/PDFPreview");
  }

  handlePDF = () => {
    this.setState({
      isLoading: true,
    });
    let record = null;
    if (this.props.record) {
      record = {
        ...this.props.record,
        reportType: "pdf",
      };
    }
    API("download", this.props.url, record)
      .then((response) => {
        if (response.status === 200 && response.data) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let file = window.URL.createObjectURL(blob);
          this.setState({
            dialog: {
              open: true,
              payload: {
                name: "Preview",
                viewType: "modal",
                mode: this.props.form ? "pdf_preview_with_params" : "read",
                source: file,
                component: this.PreviewComponent,
                form: this.props.form,
                formName: this.props.name,
                formRecord: this.props.record,
                closeCallback: this.handleClosePreview,
                applyCallback: this.reloadForm,
              },
            },
            isLoading: false,
          });
        } else {
          console.error("no data");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          this.props.error(error.response.data.fields);
        }
        this.setState({
          isLoading: false,
        });
      });
  };

  reloadForm = (record) => {
    API("download", this.props.url, { ...record, reportType: "pdf" }).then(
      (response) => {
        let blob = new Blob([response.data], { type: "application/pdf" });
        let file = window.URL.createObjectURL(blob);
        this.setState({
          dialog: {
            open: true,
            payload: {
              name: "Preview",
              viewType: "modal",
              mode: this.props.form ? "pdf_preview_with_params" : "read",
              source: file,
              component: this.PreviewComponent,
              form: this.props.form,
              formRecord: record,
              formName: this.props.name,
              closeCallback: this.handleClosePreview,
              applyCallback: this.reloadForm,
            },
          },
          isLoading: false,
        });
      },
    ).catch((error) => {
      if (error && error.response && error.response.status === 401) {
        this.props.error(error.response.data.fields);
      }
      this.setState({
        isLoading: false,
      });
    });
  };

  handleClosePreview = () => {
    this.setState({
      dialog: {
        open: false,
        payload: null,
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <Button
          key="fullscreen-pdf"
          size="sm"
          justIcon={this.props.buttonType === "form-toolbar" || this.props.justIcon}
          color={
            this.props.buttonType === "form-toolbar"
              ? "transparent"
              : this.props.color || "default"
          }
          className={this.props.buttonType || "rounded-button"}
          disabled={this.state.isLoading}
          onClick={() => this.handlePDF()}
        >
          <PrintIcon className="svg-marginless" />
          {(!this.props.justIcon && this.props.label) && (
            <span style={{ marginLeft: "5px" }}>{this.props.label}</span>
          )}
        </Button>
        <Modal
          open={this.state.dialog.open}
          onCloseDialog={this.handleClosePreview}
          payload={this.state.dialog.payload}
          maxWidth={this.props.maxWidth}
        />
      </React.Fragment>
    );
  }
}
