import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import queryString from "query-string";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

//UI
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { withStyles, Grid, InputAdornment, Tooltip } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Config from "company/Config";

//styles
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import "assets/scss/CustomLogin.css";

//icons
import PeekIcon from "@material-ui/icons/RemoveRedEye";

if (!Cookies.get("lang")) {
  var expiration_date = new Date();
  expiration_date.setFullYear(expiration_date.getFullYear() + 1);
  document.cookie = "lang=id; path=/; expires=" + expiration_date.toUTCString();
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorDescription: "",
      peek: false,
      cardAnimaton: "cardHidden",
      isLoading: false,
    };
    this.handleClickLogin = this.handleClickLogin.bind(this);
    this.handleClickPeek = this.handleClickPeek.bind(this);
    this.emailLogin = this.emailLogin.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.handleKeypressEnter = this.handleKeypressEnter.bind(this);
  }

  componentDidMount() {
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "card" });
      }.bind(this),
      700,
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  emailLogin(email, password) {
    this.setState({ isLoading: true });
    let base64String = btoa(email + ":" + password);
    let url = Config.isOfflineClient
      ? this.props.Session.host + "/api/offline/oauth/token"
      : this.props.Session.host + "/api/oauth/token";
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: "Basic " + base64String,
          },
        },
      )
      .then((response) => {
        Cookies.set("accessToken", response.data.accessToken, {
          path: "/",
          expires: moment.unix(response.data.atExpiredAt).toDate(),
        });
        Cookies.set("refreshToken", response.data.refreshToken, {
          path: "/",
          expires: moment.unix(response.data.rtExpiredAt).toDate(),
        });
        if (this.props.location.search) {
          let urlParams = queryString.parse(this.props.location.search);
          window.location.replace(decodeURIComponent(urlParams.redirect));
        } else {
          window.location.replace("/");
        }
      })
      .catch((error) => {
        if (error) {
          if (error.response && [400, 401].includes(error.response.status)) {
            if (error.response.data?.errorDescription) {
              this.setState({
                errorDescription: error.response.data.errorDescription,
              });
            } else {
              this.setState({
                errorDescription: tl("wrongEmailOrPassword")
              });
            }
            
          } else if (error.response && error.response.status === 502) {
            this.setState({
              errorDescription: tl(Cookies.get("lang"), "serverUnavailable"),
            });
          } else if (error.response && error.response.status === 404) {
            this.setState({
              errorDescription: tl(Cookies.get("lang"), "serverNotFound"),
            });
          } else {
            this.setState({
              errorDescription: tl(Cookies.get("lang"), "serverError"),
            });
          }
        }
        this.setState({ isLoading: false });
      });

    // no login
    // if (this.props.location.search) {
    // 	let urlParams = queryString.parse(this.props.location.search)
    // 	window.location.replace(decodeURIComponent(urlParams.redirect))
    // } else {
    // 	window.location.replace("/")
    // }
  }

  handleClickPeek() {
    this.setState({ peek: !this.state.peek });
  }

  handleClickLogin() {
    this.emailLogin(this.state.email, this.state.password);
  }

  forgotPassword() {
    this.props.history.push("/auth/recovery");
  }

  handleKeypressEnter(event) {
    if (event.keyCode === 13) {
      this.handleClickLogin();
    }
  }

  render() {
    const { classes } = this.props;
    let renderedConfig = null;
    if (Config.saasConfig) {
      const { Session } = this.props;
      renderedConfig = {
        companyLogo: Session.companyLogo || null,
        loginCardColor: Session.loginCardColor || null,
        loginButtonColor: Session.loginButtonColor || null,
      };
    } else {
      renderedConfig = {
        companyLogo: Config.companyLogo || null,
        loginCardColor: Config.loginCardColor || null,
        loginButtonColor: Config.loginButtonColor || null,
      };
    }
    return (
      <div className={classes.container}>
        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card login className={classes[this.state.cardAnimaton]}>
              {!renderedConfig.loginCardColor && (
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <div className="company-logo-container">
                    {renderedConfig.companyLogo ? (
                      <img
                        alt="Company Logo"
                        src={renderedConfig.companyLogo}
                      />
                    ) : (
                      <div className={classes.noImagePlaceholder}>Login</div>
                    )}
                  </div>
                </CardHeader>
              )}
              {renderedConfig.loginCardColor && (
                <div
                  style={{
                    position: "relative",
                    margin: "0 15px",
                    marginTop: "-20px",
                    borderRadius: "3px",
                    color: "#fff",
                    backgroundColor: renderedConfig.loginCardColor || "#e91e32",
                    boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14)",
                    zIndex: "3 !important",
                  }}
                >
                  <div className="company-logo-container">
                    {renderedConfig.companyLogo ? (
                      <img
                        className={classes.companyLogo}
                        alt="Company Logo"
                        src={renderedConfig.companyLogo}
                      />
                    ) : (
                      <div className={classes.noImagePlaceholder}>Login</div>
                    )}
                  </div>
                </div>
              )}
              <CardBody>
                <form>
                  <CustomInput
                    disabled={this.state.isLoading}
                    labelText={tl(Cookies.get("lang"), "email")}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      id: "email",
                      type: "email",
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                  <CustomInput
                    disabled={this.state.isLoading}
                    labelText={tl(Cookies.get("lang"), "password")}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      id: "password",
                      type: this.state.peek ? "text" : "password",
                      onChange: (event) => this.handleChange(event),
                      onKeyDown: (event) => this.handleKeypressEnter(event),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              this.state.peek
                                ? tl(Cookies.get("lang"), "hidePassword")
                                : tl(Cookies.get("lang"), "peekPassword")
                            }
                            placement="top"
                          >
                            <Button
                              justIcon
                              simple
                              onClick={() => this.handleClickPeek()}
                            >
                              <PeekIcon
                                style={{
                                  color: this.state.peek
                                    ? "#03a9f4"
                                    : "#616161",
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span
                    style={{
                      display:
                        this.state.errorDescription === "" ? "none" : "flex",
                    }}
                  >
                    {this.state.errorDescription}
                  </span>
                  <Button
                    disabled={this.state.isLoading}
                    fullWidth={true}
                    onClick={() => this.handleClickLogin()}
                    className="custom-login-button"
                    {...(renderedConfig.loginButtonColor
                      ? {
                          style: {
                            backgroundColor:
                              renderedConfig.loginButtonColor || "#e91e32",
                          },
                        }
                      : {})}
                    {...(!renderedConfig.loginButtonColor
                      ? { color: "rose" }
                      : {})}
                  >
                    {this.state.isLoading ? "Loading..." : tl("login")}
                  </Button>
                  <Button
                    simple
                    disabled={this.state.isLoading}
                    color="primary"
                    fullWidth={true}
                    onClick={() => this.forgotPassword()}
                  >
                    {tl(Cookies.get("lang"), "forgotPassword")}
                  </Button>
                </form>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(loginPageStyle)(Login));
